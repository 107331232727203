// Here you can add other styles
.rc-pagination-item{
    background: white;
    border-color: #037155;
    &:hover{
        background: #037155;
        color: white;
    }

}

.rc-pagination-item-active{
    background: #037155;
    color: white;

}